define("kredits-web/components/chart-contributions-by-type/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wfd6i6On",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"chart\"],[12],[2,\"\\n  \"],[8,\"ember-chart\",[],[[\"@type\",\"@data\",\"@options\",\"@width\",\"@height\"],[\"doughnut\",[32,0,[\"chartData\"]],[32,0,[\"chartOptions\"]],\"200\",\"200\"]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "kredits-web/components/chart-contributions-by-type/template.hbs"
    }
  });

  _exports.default = _default;
});