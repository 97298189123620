define("kredits-web/components/user-avatar/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SIZES = {
    'small': '128',
    // pixels
    'medium': '256',
    'large': '512'
  };

  var _default = Ember.Component.extend({
    contributor: null,
    tagName: 'img',
    classNames: ['avatar'],
    classNameBindings: ['size'],
    attributeBindings: ['src', 'title'],
    size: 'small',
    src: Ember.computed.alias('avatarURL'),
    title: Ember.computed.alias('contributor.name'),
    avatarURL: Ember.computed('contributor.github_uid', 'size', function () {
      var github_uid = this.contributor.github_uid;

      if (github_uid) {
        return "https://avatars2.githubusercontent.com/u/".concat(github_uid, "?v=3&s=").concat(SIZES[this.size]);
      } else {
        // TODO use custom avatar
        return '';
      }
    })
  });

  _exports.default = _default;
});