define("kredits-web/templates/signup/complete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hc80mylW",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Welcome aboard!\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content text-lg text-center\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    Congratulations. Your initial profile is now complete.\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    Why not say hi to your fellow contributors\\n    \"],[10,\"a\"],[14,6,\"https://wiki.kosmos.org/Main_Page#Community_.2F_Getting_in_touch_.2F_Getting_involved\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"in one of our chat rooms\"],[13],[2,\"?.\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"actions\"],[12],[2,\"\\n    \"],[8,\"link-to\",[[24,0,\"button small\"]],[[\"@route\"],[\"dashboard\"]],[[\"default\"],[{\"statements\":[[2,\"Return to dashboard\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "kredits-web/templates/signup/complete.hbs"
    }
  });

  _exports.default = _default;
});