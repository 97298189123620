define("kredits-web/components/topbar-account-panel/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fiv0QGp6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,1,\"user-account\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"setupInProgress\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    Connecting account...\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,2],[[32,0,[\"walletConnected\"]],[32,0,[\"kredits\",\"currentUser\"]]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[32,0,[\"kredits\",\"currentUser\",\"name\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"span\"],[14,0,\"anonymous\"],[12],[2,\"Anonymous\"],[13],[2,\"\\n      \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"signup\"],null]],[14,1,\"signup\"],[14,0,\"small\"],[14,4,\"button\"],[12],[2,\"Sign up\"],[13],[2,\"\\n\"],[6,[37,1],[[32,0,[\"walletDisconnected\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"connectWallet\"],null]],[14,1,\"connect\"],[14,0,\"small green\"],[14,4,\"button\"],[12],[2,\"Connect wallet\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"and\"]}",
    "meta": {
      "moduleName": "kredits-web/components/topbar-account-panel/template.hbs"
    }
  });

  _exports.default = _default;
});