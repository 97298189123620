define("kredits-web/components/add-contribution/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Component.extend({
    kredits: Ember.inject.service(),
    attributes: null,
    contributors: Ember.computed('kredits.contributorsSorted.[]', function () {
      return this.kredits.contributorsSorted.map(function (c) {
        return {
          id: c.id.toString(),
          name: c.name
        };
      });
    }),
    isValidContributor: Ember.computed.notEmpty('contributorId'),
    isValidKind: Ember.computed.notEmpty('kind'),
    isValidAmount: Ember.computed('amount', function () {
      return parseInt(this.amount, 10) > 0;
    }),
    isValidDescription: Ember.computed.notEmpty('description'),
    isValidUrl: Ember.computed.notEmpty('url'),
    isValid: Ember.computed.and('isValidContributor', 'isValidKind', 'isValidAmount', 'isValidDescription'),
    init: function init() {
      this._super.apply(this, arguments);

      this.set('defaultDate', (0, _moment.default)().startOf('hour').toDate());
      this.set('defaultAttr', {
        contributorId: null,
        kind: null,
        date: this.defaultDate,
        amount: null,
        description: null,
        url: null,
        details: null
      });
      this.set('attributes', Ember.assign({}, this.defaultAttr, this.attributes));
      this.reset();
    },
    reset: function reset() {
      this.setProperties(this.attributes);
    },
    actions: {
      submit: function submit(evt) {
        var _this = this;

        evt.preventDefault();

        if (!this.isValid) {
          alert('Invalid data. Please review and try again.');
          return;
        }

        var attributes = this.getProperties(Object.keys(this.attributes));
        attributes.contributorId = parseInt(this.contributorId);
        var dateInput = attributes.date instanceof Array ? attributes.date[0] : attributes.date;

        var _dateInput$toISOStrin = dateInput.toISOString().split('T'),
            _dateInput$toISOStrin2 = _slicedToArray(_dateInput$toISOStrin, 2),
            date = _dateInput$toISOStrin2[0],
            time = _dateInput$toISOStrin2[1];

        var _ref = [date, time];
        attributes.date = _ref[0];
        attributes.time = _ref[1];
        this.set('inProgress', true);
        this.save(attributes).then(function
          /*contribution*/
        () {
          _this.reset();
        }, function (err) {
          console.warn(err);
          window.alert('Something went wrong. Check the browser console for details.');
        }).finally(function () {
          return _this.set('inProgress', false);
        });
      }
    }
  });

  _exports.default = _default;
});