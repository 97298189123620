define("kredits-web/helpers/fmt-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fmtNumber(number) {
    var lang = navigator.language || navigator.userLanguage;
    return number.toLocaleString(lang);
  });

  _exports.default = _default;
});