define("kredits-web/controllers/contributions/resubmit", ["exports", "kredits-web/controllers/contributions/new"], function (_exports, _new) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _new.default.extend({
    attributes: null
  });

  _exports.default = _default;
});