define("kredits-web/components/budget-balances/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2n/5IhCH",
    "block": "{\"symbols\":[\"balance\"],\"statements\":[[10,\"table\"],[15,0,[31,[\"token-balances \",[30,[36,1],[[32,0,[\"loading\"]],\"loading\"],null]]]],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Token\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Amount\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Fiat value\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"balancesSorted\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"th\"],[12],[2,\"\\n          \"],[10,\"img\"],[15,\"src\",[32,1,[\"token\",\"icon\"]]],[15,\"alt\",[32,1,[\"token\",\"description\"]]],[15,\"title\",[32,1,[\"token\",\"description\"]]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[14,0,\"amount\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[32,1,[\"confirmed_balance\"]]],null]],[2,\" \"],[10,\"span\"],[14,0,\"unit\"],[12],[2,\"sats\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[14,0,\"fiat-amount\"],[12],[2,\"\\n          ~\"],[1,[32,1,[\"balanceUSD\"]]],[2,\" USD\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"fmt-number\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "kredits-web/components/budget-balances/template.hbs"
    }
  });

  _exports.default = _default;
});