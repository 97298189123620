define("kredits-web/helpers/item-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var _default = Ember.Helper.extend({
    kredits: Ember.inject.service(),
    currentBlock: Ember.computed.alias('kredits.currentBlock'),
    compute: function compute(_ref) {
      var _ref2 = _slicedToArray(_ref, 1),
          item = _ref2[0];

      this.setupRecompute(item);
      var status = [];

      if (item.vetoed) {
        status.push('vetoed');
      } else if (item.confirmedAt > this.currentBlock) {
        status.push('unconfirmed');
      } else {
        status.push('confirmed');
      }

      if (item.hasPendingChanges) {
        status.push('pending');
      }

      return status.join(' ');
    },
    destroy: function destroy() {
      if (this.teardown) this.teardown();

      this._super.apply(this, arguments);
    },
    setupRecompute: function setupRecompute(item) {
      var _this = this;

      if (this.teardown) this.teardown();
      item.addObserver('vetoed', this, this.triggerRecompute);
      item.addObserver('confirmedAt', this, this.triggerRecompute);
      item.addObserver('currentBlock', this, this.triggerRecompute);
      item.addObserver('hasPendingChanges', this, this.triggerRecompute);

      this.teardown = function () {
        item.removeObserver('vetoed', _this, _this.triggerRecompute);
        item.removeObserver('confirmedAt', _this, _this.triggerRecompute);
        item.removeObserver('currentBlock', _this, _this.triggerRecompute);
        item.removeObserver('hasPendingChanges', _this, _this.triggerRecompute);
      };
    },
    triggerRecompute: function triggerRecompute() {
      Ember.run.once(this, this.recompute);
    }
  });

  _exports.default = _default;
});