define("kredits-web/components/contribution-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    tagName: 'div',
    classNames: ['contributions'],
    selectedContribution: null,
    showQuickFilter: false,
    hideSmallContributions: false,
    contributorId: null,
    contributionKind: null,
    kredits: Ember.inject.service(),
    contributorsSorting: Object.freeze(['name:asc']),
    contributors: Ember.computed.sort('kredits.contributors', 'contributorsSorting'),
    contributorsActive: Ember.computed('contributors.[]', 'contributions', function () {
      var activeIds = new Set(this.contributions.mapBy('contributorId'));
      return this.contributors.filter(function (c) {
        return activeIds.has(c.id);
      });
    }),
    contributionKinds: Ember.computed('contributions.[]', function () {
      return this.contributions.mapBy('kind').uniq();
    }),
    contributionsFiltered: Ember.computed('contributions.[]', 'hideSmallContributions', 'contributorId', 'contributionKind', function () {
      var _this = this;

      return this.contributions.filter(function (c) {
        var included = true;

        if (_this.hideSmallContributions && c.amount <= 500) {
          included = false;
        }

        if (Ember.isPresent(_this.contributorId) && c.contributorId !== parseInt(_this.contributorId)) {
          included = false;
        }

        if (Ember.isPresent(_this.contributionKind) && c.kind !== _this.contributionKind) {
          included = false;
        }

        return included;
      });
    }),
    actions: {
      veto: function veto(contributionId) {
        if (this.contractInteractionEnabled) {
          this.vetoContribution(contributionId);
        } else {
          window.alert('Only members can veto contributions. Please ask someone to set you up.');
        }
      },
      openContributionDetails: function openContributionDetails(contribution) {
        this.router.transitionTo('dashboard.contributions.show', contribution);
      }
    }
  });

  _exports.default = _default;
});