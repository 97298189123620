define("kredits-web/components/reimbursement-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iEvKZZIl",
    "block": "{\"symbols\":[\"item\",\"@loading\"],\"statements\":[[10,\"ul\"],[15,0,[31,[\"item-list collapsible spaced reimbursement-list \",[30,[36,0],[[32,2],\"loading\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"itemsSorted\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"reimbursement-item\",[],[[\"@reimbursement\"],[[32,1]]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "kredits-web/components/reimbursement-list/template.hbs"
    }
  });

  _exports.default = _default;
});