define("kredits-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7nDDmrRX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,1,\"topbar\"],[12],[2,\"\\n  \"],[10,\"h1\"],[12],[8,\"link-to\",[],[[\"@route\"],[\"dashboard\"]],[[\"default\"],[{\"statements\":[[2,\"Kredits\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[8,\"topbar-account-panel\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"main-navigation-menu\",[],[[],[]],null],[2,\"\\n\\n\"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "kredits-web/templates/application.hbs"
    }
  });

  _exports.default = _default;
});