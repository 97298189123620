define("kredits-web/components/add-contributor/component", ["exports", "web3-utils"], function (_exports, _web3Utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    kredits: Ember.inject.service(),
    attributes: null,
    isValidAccount: Ember.computed('account', function () {
      return (0, _web3Utils.isAddress)(this.account);
    }),
    isValidName: Ember.computed.notEmpty('name'),
    isValidURL: Ember.computed.notEmpty('url'),
    isValidGithubUID: Ember.computed.notEmpty('github_uid'),
    isValidGithubUsername: Ember.computed.notEmpty('github_username'),
    isValidGiteaUsername: Ember.computed.notEmpty('gitea_username'),
    isValidWikiUsername: Ember.computed.notEmpty('wiki_username'),
    isValidZoomDisplayName: Ember.computed.notEmpty('zoom_display_name'),
    isValid: Ember.computed.and('isValidAccount', 'isValidName', 'isValidGithubUID'),
    inProgress: false,
    init: function init() {
      this._super.apply(this, arguments);

      this.setDefaultAttributes();
      this.reset();
    },
    setDefaultAttributes: function setDefaultAttributes() {
      if (Ember.isPresent(this.attributes)) {
        return;
      }

      this.set('attributes', {
        account: null,
        name: null,
        kind: 'person',
        url: null,
        github_username: null,
        github_uid: null,
        gitea_username: null,
        wiki_username: null,
        zoom_display_name: null
      });
    },
    reset: function reset() {
      this.setProperties(this.attributes);
    },
    actions: {
      submit: function submit(evt) {
        var _this = this;

        evt.preventDefault();

        if (!this.kredits.currentUserIsCore) {
          window.alert('Only core team members can edit profiles. Please ask someone to set you up.');
          return;
        }

        if (!this.isValid) {
          window.alert('Invalid data. Please review and try again.');
          return;
        }

        var attributes = Object.keys(this.attributes);
        var contributor = this.getProperties(attributes);
        this.set('inProgress', true);
        this.save(contributor).then(function () {
          _this.reset();
        }).catch(function (err) {
          console.warn(err);
          window.alert('Something went wrong. Please check the browser console.');
        }).finally(function () {
          _this.set('inProgress', false);

          _this.router.transitionTo('dashboard');
        });
      }
    }
  });

  _exports.default = _default;
});