define("kredits-web/templates/contributions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FD6gV6zT",
    "block": "{\"symbols\":[],\"statements\":[[10,\"main\"],[14,0,\"center-column\"],[12],[2,\"\\n\\n  \"],[10,\"section\"],[14,1,\"add-contribution\"],[12],[2,\"\\n    \"],[10,\"header\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Submit a contribution\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n      \"],[8,\"add-contribution\",[],[[\"@attributes\",\"@save\"],[[32,0,[\"model\",\"params\"]],[30,[36,0],[[32,0],\"save\"],null]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "kredits-web/templates/contributions/new.hbs"
    }
  });

  _exports.default = _default;
});