define("kredits-web/models/reimbursement", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    // Contract
    id: null,
    recipientId: null,
    token: null,
    amount: null,
    confirmedAt: null,
    vetoed: null,
    ipfsHash: null,
    // contributor model instance
    recipient: null,
    // TODO contributor who submitted the reimbursement
    // recordedBy: null,
    // IPFS
    expenses: null,
    // Array of expense objects
    pendingTx: null,
    iso8601Date: Ember.computed('date', 'time', function () {
      return this.time ? "".concat(this.date, "T").concat(this.time) : this.date;
    }),
    jsDate: Ember.computed('iso8601Date', function () {
      return (0, _moment.default)(this.iso8601Date).toDate();
    }),
    hasPendingChanges: Ember.computed('pendingTx', function () {
      return Ember.isPresent(this.pendingTx);
    }),
    pendingStatus: Ember.computed('pendingTx', function () {
      return Ember.isPresent(this.pendingTx) ? 'isPending' : 'notPending';
    }),
    serialize: function serialize() {
      return JSON.stringify(this);
    }
  });

  _exports.default = _default;
});