define("kredits-web/utils/read-file-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(file) {
    var reader = new FileReader();
    return new Promise(function (resolve, reject) {
      reader.onload = function (event) {
        return resolve(event.target.result);
      };

      reader.onerror = function (error) {
        return reject(error);
      };

      reader.readAsText(file);
    });
  }
});