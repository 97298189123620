define("kredits-web/utils/group-by", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = groupBy;

  function groupBy(collection, property) {
    var groups = Ember.A();
    var items = collection;

    if (items) {
      items.forEach(function (item) {
        var value = item[property];
        var group = groups.findBy('value', value);

        if (Ember.isPresent(group)) {
          group.items.push(item);
        } else {
          group = {
            property: property,
            value: value,
            items: [item]
          };
          groups.push(group);
        }
      });
    }

    return groups;
  }
});