define("kredits-web/routes/dashboard/contributors/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kredits: Ember.inject.service(),
    contributors: Ember.computed.alias('kredits.contributors'),
    model: function model(params) {
      var contributor = this.contributors.findBy('id', params.id);

      if (contributor) {
        return contributor;
      } else {
        return this.kredits.fetchContributor(params.id);
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('dashboard').setProperties({
        showDetailsPane: true,
        selectedContributorId: model.id
      });
    },
    deactivate: function deactivate() {
      this.controllerFor('dashboard').setProperties({
        showDetailsPane: false,
        selectedContributorId: null
      });
    }
  });

  _exports.default = _default;
});