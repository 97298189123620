define("kredits-web/routes/contributors/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kredits: Ember.inject.service(),
    contributors: Ember.computed.alias('kredits.contributors'),
    model: function model(params) {
      return this.kredits.contributors.findBy('id', params.id);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('attributes', {
        account: model.account,
        name: model.name,
        kind: model.kind,
        url: model.url,
        github_username: model.github_username,
        github_uid: model.github_uid,
        gitea_username: model.gitea_username,
        wiki_username: model.wiki_username,
        zoom_display_name: model.zoom_display_name
      });
    }
  });

  _exports.default = _default;
});