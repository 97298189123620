define("kredits-web/components/expense-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Vj8N2eKw",
    "block": "{\"symbols\":[\"expense\",\"tag\",\"@removeExpenseItem\",\"@expenses\"],\"statements\":[[10,\"ul\"],[14,0,\"expense-list\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"expense-item\"],[12],[2,\"\\n      \"],[10,\"h4\"],[12],[2,\"\\n        \"],[10,\"span\"],[14,0,\"date\"],[12],[1,[30,[36,2],[[32,1,[\"date\"]]],null]],[2,\":\"],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"title\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"amount\"],[12],[2,\"\\n        \"],[1,[30,[36,3],[[32,1,[\"amount\"]],[32,1,[\"currency\"]]],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"description\"],[12],[2,\"\\n        \"],[1,[32,1,[\"description\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"tags\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,1,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"button\"],[14,0,\"small yellow\"],[14,\"role\",\"none\"],[14,4,\"button\"],[12],[2,\"\\n            \"],[8,\"icon-tag\",[],[[],[]],null],[1,[32,2]],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"      \"],[13],[2,\"\\n\"],[6,[37,6],[[32,0,[\"showDeleteButton\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n          \"],[11,\"button\"],[24,0,\"danger small\"],[24,4,\"button\"],[4,[38,1],[\"click\",[30,[36,0],[[32,3],[32,1]],null]],null],[12],[2,\"delete\"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"fn\",\"on\",\"fmt-date-localized\",\"fmt-fiat-currency\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "kredits-web/components/expense-list/template.hbs"
    }
  });

  _exports.default = _default;
});