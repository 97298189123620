define("kredits-web/components/external-account-link/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    account: null,
    iconComponentName: Ember.computed('account.site', function () {
      if (this.account.site.match(/github|gitea|wiki|zoom/)) {
        return 'icon-account-' + this.account.site.replace(/\./g, '-');
      } else {
        return 'icon-web-globe';
      }
    })
  });

  _exports.default = _default;
});