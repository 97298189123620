define("kredits-web/helpers/fmt-crypto-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fmtCryptoCurrency(params
  /*, hash*/
  ) {
    var fmtAmount;
    var amount = params[0];
    var code = params[1];

    switch (code) {
      case 'RBTC':
        fmtAmount = amount / 1000000000000000000;
        break;

      case 'BTC':
        fmtAmount = amount / 100000000;
        break;
    }

    return fmtAmount;
  });

  _exports.default = _default;
});