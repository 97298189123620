define("kredits-web/components/external-account-link/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rwjEP1kx",
    "block": "{\"symbols\":[],\"statements\":[[10,\"a\"],[15,6,[32,0,[\"account\",\"url\"]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[15,\"title\",[31,[[32,0,[\"account\",\"username\"]],\" on \",[32,0,[\"account\",\"site\"]]]]],[12],[2,\"\\n  \"],[10,\"span\"],[14,0,\"site\"],[12],[1,[32,0,[\"account\",\"site\"]]],[13],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"iconComponentName\"]]],null]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\"]}",
    "meta": {
      "moduleName": "kredits-web/components/external-account-link/template.hbs"
    }
  });

  _exports.default = _default;
});