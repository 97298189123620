define("kredits-web/components/confirmed-in/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wZ+crHjf",
    "block": "{\"symbols\":[\"@confirmedAtBlock\"],\"statements\":[[6,[37,0],[[32,0,[\"isConfirmed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Confirmed at block \"],[10,\"strong\"],[12],[1,[32,1]],[13],[2,\" (~ \"],[1,[32,0,[\"confirmedInHumanTime\"]]],[2,\" ago)\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"Confirming in \"],[10,\"strong\"],[12],[1,[32,0,[\"confirmedInBlocks\"]]],[13],[2,\" blocks (~ \"],[1,[32,0,[\"confirmedInHumanTime\"]]],[2,\")\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "meta": {
      "moduleName": "kredits-web/components/confirmed-in/template.hbs"
    }
  });

  _exports.default = _default;
});