define("kredits-web/utils/is-valid-amount", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isValidAmount;

  function isValidAmount(inputAmount) {
    var amount = parseFloat(inputAmount);

    if (Number.isNaN(amount)) {
      return false;
    } else {
      return amount > 0;
    }
  }
});