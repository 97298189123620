define("kredits-web/routes/signup/github", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kredits: Ember.inject.service(),
    redirect: function redirect() {
      this._super.apply(this, arguments);

      var accessToken;

      try {
        accessToken = window.location.hash.match(/access_token=(.+)/)[1];
      } catch (error) {
        /* ignore */
      }

      if (Ember.isEmpty(accessToken) || accessToken === 'undefined') {
        console.error('No GitHub access token found.');
        this.transitionTo('signup');
        return;
      }

      this.kredits.set('githubAccessToken', accessToken);
      this.transitionTo('signup.account');
    }
  });

  _exports.default = _default;
});