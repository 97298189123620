define("kredits-web/helpers/is-rejected", ["exports", "ember-promise-helpers/helpers/is-rejected"], function (_exports, _isRejected) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _isRejected.default;
    }
  });
});