define("kredits-web/controllers/contributors/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kredits: Ember.inject.service(),
    actions: {
      save: function save(contributor) {
        return this.kredits.addContributor(contributor);
      }
    }
  });

  _exports.default = _default;
});