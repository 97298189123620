define("kredits-web/templates/signup/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NAvcexMu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Create your contributor profile\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content text-lg\"],[12],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    You have already contributed to a Kosmos project, or you are interested\\n    in contributing in the near future? Fantastic!\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    In order to earn kredits for your contributions, you will need a\\n    registered contributor profile. The quickest way to register is by\\n    connecting one of the following accounts:\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"actions\"],[12],[2,\"\\n    \"],[10,\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"connectGithub\"],null]],[14,0,\"icon\"],[14,4,\"button\"],[12],[2,\"\\n      \"],[8,\"icon-account-github-com\",[],[[],[]],null],[2,\"\\n      Connect GitHub\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "kredits-web/templates/signup/index.hbs"
    }
  });

  _exports.default = _default;
});