define("kredits-web/controllers/contributions/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kredits: Ember.inject.service(),
    queryParams: ['contributorId', 'kind', 'amount'],
    contributors: Ember.computed.alias('kredits.contributors'),
    minedContributors: Ember.computed.filterBy('contributors', 'id'),
    contributorsSorting: Object.freeze(['name:asc']),
    sortedContributors: Ember.computed.sort('minedContributors', 'contributorsSorting'),
    actions: {
      save: function save(contribution) {
        var _this = this;

        var contributor = this.contributors.findBy('id', contribution.contributorId);
        contribution.contributorIpfsHash = contributor.ipfsHash;
        return this.kredits.addContribution(contribution).then(function (contribution) {
          _this.transitionToRoute('index');

          return contribution;
        });
      }
    }
  });

  _exports.default = _default;
});