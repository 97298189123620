define("kredits-web/templates/signup/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "juAngjy3",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[12],[2,\"\\n  \"],[10,\"h2\"],[12],[2,\"Complete your contributor profile\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"content text-lg\"],[12],[2,\"\\n  \"],[10,\"p\"],[14,0,\"mb-8\"],[12],[2,\"\\n    Kredits allow you to to earn rewards for your contributions, in the form of\\n    dynamic open-source grants. As a regular contributor, you can also take\\n    part in the community's project governance and finances.\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[12],[2,\"\\n    In order to interact with the system you will need a\\n    \"],[10,\"a\"],[14,6,\"https://rootstock.io\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[12],[2,\"Rootstock\"],[13],[2,\"\\n    wallet/account.\\n  \"],[13],[2,\"\\n  \"],[10,\"form\"],[12],[2,\"\\n    \"],[10,\"p\"],[12],[2,\"\\n      \"],[10,\"label\"],[12],[2,\"\\n        Rootstock address:\"],[10,\"br\"],[12],[13],[2,\"\\n        \"],[8,\"input\",[[24,\"placeholder\",\"0xF18E631Ea191aE4ebE70046Fcb01a436554421BA4\"],[16,0,[30,[36,0],[[32,0,[\"isValidEthAccount\"]],\"valid\",\"\"],null]]],[[\"@type\",\"@value\"],[\"text\",[32,0,[\"accountAddress\"]]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"p\"],[14,0,\"actions\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,\"disabled\",[32,0,[\"signupButtonDisabled\"]]],[24,4,\"button\"],[4,[38,2],[\"click\",[30,[36,1],[[32,0,[\"completeSignup\"]]],null]],null],[12],[2,\"\\n      Complete my profile\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"fn\",\"on\"]}",
    "meta": {
      "moduleName": "kredits-web/templates/signup/account.hbs"
    }
  });

  _exports.default = _default;
});