define("kredits-web/controllers/contributors/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    kredits: Ember.inject.service(),
    actions: {
      save: function save(attributes) {
        return this.kredits.updateContributor(this.model.id, attributes);
      }
    }
  });

  _exports.default = _default;
});