define("kredits-web/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vyvqw+Xa",
    "block": "{\"symbols\":[],\"statements\":[[10,\"main\"],[14,0,\"center-column\"],[12],[2,\"\\n  \"],[10,\"section\"],[14,1,\"signup\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "kredits-web/templates/signup.hbs"
    }
  });

  _exports.default = _default;
});