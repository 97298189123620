define("kredits-web/components/contributor-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H19rOGuf",
    "block": "{\"symbols\":[\"c\",\"@selectedContributorId\",\"@showUnconfirmedKredits\",\"@loading\",\"@contributorList\"],\"statements\":[[10,\"table\"],[15,0,[31,[\"contributor-list \",[30,[36,2],[[32,4],\"loading\"],null]]]],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[14,\"role\",\"button\"],[15,\"onclick\",[30,[36,0],[[32,0],\"openContributorDetails\",[32,1,[\"contributor\"]]],null]],[15,0,[31,[[30,[36,2],[[30,[36,3],[[32,1,[\"contributor\"]]],null],\"current-user\"],null],\" \",[30,[36,2],[[30,[36,1],[[32,1,[\"contributor\",\"id\"]],[32,2]],null],\"selected\"],null]]]],[12],[2,\"\\n        \"],[10,\"td\"],[14,0,\"person\"],[12],[2,\"\\n          \"],[8,\"user-avatar\",[],[[\"@contributor\"],[[32,1,[\"contributor\"]]]],null],[2,\" \"],[1,[32,1,[\"contributor\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"td\"],[14,0,\"kredits\"],[12],[2,\"\\n          \"],[10,\"span\"],[14,0,\"amount\"],[12],[2,\"\\n\"],[6,[37,2],[[32,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"              \"],[1,[32,1,[\"amountTotal\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"              \"],[1,[32,1,[\"amountConfirmed\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[13],[2,\"\\n          \"],[10,\"span\"],[14,0,\"symbol\"],[12],[2,\"₭S\"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"eq\",\"if\",\"is-current-user\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "kredits-web/components/contributor-list/template.hbs"
    }
  });

  _exports.default = _default;
});