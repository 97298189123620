define("kredits-web/components/contributor-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: '',
    router: Ember.inject.service(),
    selectedContributorId: null,
    actions: {
      openContributorDetails: function openContributorDetails(contributor) {
        this.router.transitionTo('dashboard.contributors.show', contributor);
      }
    }
  });

  _exports.default = _default;
});