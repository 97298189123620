define("kredits-web/utils/format-kredits", ["exports", "ethers"], function (_exports, _ethers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default(value) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

    var etherValue = _ethers.default.utils.formatEther(value);

    if (!options.decimals) {
      etherValue = parseInt(etherValue).toString();
    }

    return etherValue;
  }
});