define("kredits-web/utils/process-contributor-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = processContributorData;

  function processContributorData(data) {
    var _data$contributionsCo;

    var processed = {
      balance: data.balanceInt,
      totalKreditsEarned: data.totalKreditsEarned,
      contributionsCount: (_data$contributionsCo = data.contributionsCount) === null || _data$contributionsCo === void 0 ? void 0 : _data$contributionsCo.toNumber()
    };
    var otherProperties = ['id', 'account', 'accounts', 'ipfsHash', 'isCore', 'kind', 'name', 'url', 'github_username', 'github_uid', 'wiki_username', 'zoom_display_name'];
    otherProperties.forEach(function (prop) {
      processed[prop] = data[prop];
    });
    return processed;
  }
});