define("kredits-web/utils/get-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;

  function _default() {
    return navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
  }
});