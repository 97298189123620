define("kredits-web/routes/contributions/resubmit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kredits: Ember.inject.service(),
    model: function model(params) {
      return this.kredits.contributions.findBy('id', parseInt(params.id));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      controller.set('attributes', model.getProperties(['contributorId', 'kind', 'amount', 'description', 'url', 'details']));
      controller.set('attributes.date', model.jsDate);
    }
  });

  _exports.default = _default;
});