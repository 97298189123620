define("kredits-web/routes/dashboard/contributions/show", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    kredits: Ember.inject.service(),
    contributions: Ember.computed.alias('kredits.contributions'),
    model: function model(params) {
      return this.contributions.findBy('id', parseInt(params.id));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);

      this.controllerFor('dashboard').setProperties({
        showDetailsPane: true,
        selectedContributionId: model.id
      });
    },
    deactivate: function deactivate() {
      this.controllerFor('dashboard').setProperties({
        showDetailsPane: false,
        selectedContributionId: null
      });
    }
  });

  _exports.default = _default;
});