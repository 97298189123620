define("kredits-web/templates/components/icon-account-wiki-kosmos-org", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jd1PFucm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,0,\"icon-wiki\"],[14,\"width\",\"120\"],[14,\"height\",\"120\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 120 120\"],[14,\"xml:space\",\"preserve\",\"http://www.w3.org/XML/1998/namespace\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:cc\",\"http://creativecommons.org/ns#\"],[14,\"xmlns:dc\",\"http://purl.org/dc/elements/1.1/\"],[14,\"xmlns:rdf\",\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"],[12],[2,\"\\n  \"],[10,\"g\"],[14,\"transform\",\"translate(18.1,3)\"],[12],[2,\"\\n    \"],[10,\"polygon\"],[14,0,\"fg\"],[14,\"transform\",\"translate(-6.2,9)\"],[14,\"points\",\"6.2 5 45.7 20.5 45.7 91 6.2 75.5\"],[12],[13],[2,\"\\n    \"],[10,\"polygon\"],[14,0,\"fg\"],[14,\"transform\",\"translate(-6.2,9)\"],[14,\"points\",\"90 5 50.5 20.5 50.5 91 90 75.5\"],[12],[13],[2,\"\\n    \"],[10,\"polygon\"],[14,0,\"fg\"],[14,\"transform\",\"translate(-6.2,9)\"],[14,\"points\",\"87 88 87 81.8 60.4 91\"],[12],[13],[2,\"\\n    \"],[10,\"polygon\"],[14,0,\"fg\"],[14,\"transform\",\"translate(-6.2,9)\"],[14,\"points\",\"9.2 88 9.2 81.8 34.9 91\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "kredits-web/templates/components/icon-account-wiki-kosmos-org.hbs"
    }
  });

  _exports.default = _default;
});