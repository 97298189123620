define("kredits-web/components/chart-contributions-by-type/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var categoryColors = {
    community: "#fb6868",
    design: "#fbe468",
    dev: "#e068fb",
    docs: "#97fb68",
    ops: "#8f68fb"
  };

  var _default = Ember.Component.extend({
    contributions: null,
    chartOptions: Object.freeze({
      legend: {
        display: false
      }
    }),
    chartData: Ember.computed('contributions', function () {
      var kredits = this.contributions.filterBy('vetoed', false).map(function (c) {
        return {
          kind: c.kind,
          amount: c.amount
        };
      }).reduce(function (kinds, c) {
        if (c.kind in kinds) {
          kinds[c.kind] = kinds[c.kind] + c.amount;
        } else {
          kinds[c.kind] = c.amount;
        }

        return kinds;
      }, {});
      return {
        datasets: [{
          data: [kredits['community'], kredits['design'], kredits['dev'], kredits['ops'], kredits['docs']],
          borderColor: [categoryColors.community, categoryColors.design, categoryColors.dev, categoryColors.ops, categoryColors.docs],
          borderWidth: 1
        }],
        labels: ['Community', 'Design', 'Development', 'Operations & Infrastructure', 'Documentation']
      };
    })
  });

  _exports.default = _default;
});