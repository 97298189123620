define("kredits-web/templates/components/icon-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JuVyAs3U",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"width\",\"24\"],[14,\"height\",\"24\"],[14,\"viewBox\",\"0 0 24 24\"],[14,\"fill\",\"none\"],[14,\"stroke\",\"currentColor\"],[14,\"stroke-width\",\"2\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,0,\"feather feather-tag\"],[12],[10,\"path\"],[14,\"d\",\"M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z\"],[12],[13],[10,\"line\"],[14,\"x1\",\"7\"],[14,\"y1\",\"7\"],[14,\"x2\",\"7.01\"],[14,\"y2\",\"7\"],[12],[13],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "kredits-web/templates/components/icon-tag.hbs"
    }
  });

  _exports.default = _default;
});