define("kredits-web/templates/components/icon-warning", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s2ZzsQPB",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"60px\"],[14,\"height\",\"60px\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 60 60\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:cc\",\"http://creativecommons.org/ns#\"],[14,\"xmlns:dc\",\"http://purl.org/dc/elements/1.1/\"],[14,\"xmlns:rdf\",\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"],[12],[2,\"\\n  \"],[10,\"g\"],[14,\"fill\",\"none\"],[14,\"fill-rule\",\"evenodd\"],[12],[2,\"\\n    \"],[10,\"g\"],[14,\"transform\",\"translate(-479,-238)\"],[14,\"stroke\",\"#fbe468\"],[14,\"stroke-linecap\",\"round\"],[14,\"stroke-linejoin\",\"round\"],[14,\"stroke-width\",\"1\"],[12],[2,\"\\n      \"],[10,\"g\"],[14,\"transform\",\"translate(482,244)\"],[12],[2,\"\\n        \"],[10,\"a\"],[14,\"transform\",\"translate(0,2)\"],[12],[2,\"\\n          \"],[10,\"polygon\"],[14,1,\"Triangle-58\"],[14,\"points\",\"0.15321 44 27 0 53.847 44\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"rect\"],[14,\"x\",\"25\"],[14,\"y\",\"13\"],[14,\"width\",\"4\"],[14,\"height\",\"21\"],[12],[13],[2,\"\\n        \"],[10,\"a\"],[14,\"transform\",\"translate(0,2)\"],[12],[2,\"\\n          \"],[10,\"circle\"],[14,\"cx\",\"27\"],[14,\"cy\",\"38\"],[14,\"r\",\"2\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "kredits-web/templates/components/icon-warning.hbs"
    }
  });

  _exports.default = _default;
});