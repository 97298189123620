define("kredits-web/utils/process-contribution-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = processContributionData;

  function processContributionData(data) {
    var options = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    var processed = {};

    if (data.confirmedAtBlock && typeof data.confirmedAtBlock.toNumber === 'function') {
      processed.confirmedAt = data.confirmedAtBlock.toNumber();
    } else if (data.confirmedAt !== 'undefined') {
      processed.confirmedAt = data.confirmedAt;
    }

    var otherProperties = ['id', 'contributorId', 'amount', 'vetoed', 'ipfsHash', 'kind', 'description', 'url', 'date', 'time', 'pendingTx'];
    otherProperties.forEach(function (prop) {
      processed[prop] = data[prop];
    });

    if (options.includeDetails) {
      processed.details = data.details;
    }

    return processed;
  }
});