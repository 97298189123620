define("kredits-web/templates/components/icon-account-zoom-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t+FK8DQJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"svg\"],[14,\"width\",\"256\"],[14,\"height\",\"256\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 67.733 67.733\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"xmlns:cc\",\"http://creativecommons.org/ns#\"],[14,\"xmlns:dc\",\"http://purl.org/dc/elements/1.1/\"],[14,\"xmlns:rdf\",\"http://www.w3.org/1999/02/22-rdf-syntax-ns#\"],[12],[2,\"\\n  \"],[10,\"g\"],[14,\"transform\",\"translate(0 -229.27)\"],[12],[2,\"\\n    \"],[10,\"g\"],[14,\"transform\",\"matrix(.90278 0 0 .90278 6.5684 141.05)\"],[14,\"fill-rule\",\"evenodd\"],[12],[2,\"\\n      \"],[10,\"path\"],[14,0,\"fg\"],[14,\"d\",\"m32.67 117.1c2.2056 0.1405 3.0528 0.27041 4.1992 0.64479 3.9359 1.2854 6.7418 4.6736 7.3059 8.8223 0.06615 0.48578 0.13996 1.0253 0.16431 1.1986 0.29104 2.0743 0.07488 21.552-0.25003 22.55-0.59082 1.814-1.901 2.7993-4.0537 3.0488-1.3671 0.15849-30.564 0.0701-31.846-0.0963-5.2843-0.68659-8.8934-4.001-9.7398-8.9445-0.38418-2.2439-0.62362-20.748-0.29871-23.084 0.34422-2.4746 1.7264-3.797 4.3212-4.1338 0.98769-0.12832 28.191-0.13361 30.197-6e-3\"],[12],[13],[2,\"\\n      \"],[10,\"path\"],[14,0,\"fg\"],[14,\"d\",\"m61.114 118.11c0.56621 0.4072 0.97816 1.0565 1.1885 1.874 0.19553 0.75909 0.24474 28.04 0.05371 29.747-0.37333 3.3361-2.6305 4.0137-5.5264 1.6584-7.9722-6.4841-9.021-7.5734-9.6795-10.052-0.26008-0.97895-0.33152-10.629-0.08811-11.876 0.38021-1.9457 1.2594-3.1946 3.7383-5.3099 7.4491-6.3564 7.5914-6.4569 9.0522-6.4008 0.70723 0.0272 0.8599 0.0709 1.2613 0.3593\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "kredits-web/templates/components/icon-account-zoom-us.hbs"
    }
  });

  _exports.default = _default;
});