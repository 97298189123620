define("kredits-web/models/contributor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    // Contract
    id: null,
    account: null,
    balance: 0,
    totalKreditsEarned: 0,
    contributionsCount: 0,
    isCore: false,
    ipfsHash: null,
    // IPFS
    kind: null,
    name: null,
    url: null,
    github_username: null,
    github_uid: null,
    wiki_username: null,
    zoom_display_name: null,
    serialize: function serialize() {
      return JSON.stringify(this);
    }
  });

  _exports.default = _default;
});