define("kredits-web/helpers/fmt-date-localized", ["exports", "kredits-web/utils/get-locale"], function (_exports, _getLocale) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function fmtDateLocalized(dateStr) {
    var date = new Date(dateStr);
    var locale = (0, _getLocale.default)();
    return new Intl.DateTimeFormat(locale).format(date);
  });

  _exports.default = _default;
});