define("kredits-web/utils/process-reimbursement-data", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = processReimbursementData;

  function processReimbursementData(data) {
    var processed = {
      amount: data.amount.toNumber()
    };

    if (data.confirmedAtBlock && typeof data.confirmedAtBlock.toNumber === 'function') {
      processed.confirmedAt = data.confirmedAtBlock.toNumber();
    } else if (data.confirmedAt !== 'undefined') {
      processed.confirmedAt = data.confirmedAt;
    }

    var otherProperties = ['id', 'recipientId', 'token', 'vetoed', 'ipfsHash', 'expenses', 'pendingTx'];
    otherProperties.forEach(function (prop) {
      processed[prop] = data[prop];
    });
    return processed;
  }
});