define("kredits-web/helpers/sats-to-btc", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Helper.helper(function satsToBtc(amount
  /*, hash*/
  ) {
    return amount / 100000000;
  });

  _exports.default = _default;
});