define("kredits-web/templates/reimbursements/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4ZGew5Lo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"main\"],[14,0,\"center-column\"],[12],[2,\"\\n\\n  \"],[10,\"section\"],[14,1,\"add-item\"],[12],[2,\"\\n    \"],[10,\"header\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"Submit a Reimbursement\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n      \"],[8,\"add-reimbursement\",[],[[\"@attributes\"],[[32,0,[\"model\",\"params\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "kredits-web/templates/reimbursements/new.hbs"
    }
  });

  _exports.default = _default;
});