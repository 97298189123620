define("kredits-web/controllers/dashboard/contributions/show", ["exports", "kredits-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    ipfsGatewayUrl: Ember.computed(function () {
      return _environment.default.ipfs.gatewayUrl;
    })
  });

  _exports.default = _default;
});